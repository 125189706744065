<template>
  <div>
    <v-snackbar :color="notification.color" top v-model="notification.state">
      <div v-html="notification.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          color="white"
          v-bind="attrs"
          @click="notification.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-card class="rounded-lg">
      <v-card-title> Form Maintenance </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <ValidationObserver ref="observer">
          <v-form>
            <!-- IMAGE UPLOAD -->
            <div v-if="fileUpload[0] || filepreview" class="text-right mb-1 py-0">
              <v-btn color="#d31145" icon depressed small @click="removeFile()">
                <v-icon>ri-delete-bin-6-line</v-icon>
              </v-btn>
            </div>

            <div
              :class="[
                'dropZone',
                dragging ? 'dropZone-over' : 'cursor-pointer',
              ]"
              @dragenter="dragging = true"
              @dragleave="dragging = false"
            >
              <div v-if="!fileUpload[0] && filepreview == ''" class="dropZone-info" @drag="onChange">
                <span class="fa fa-cloud-upload dropZone-title"></span>
                <div class="dropZone-upload-limit-info">
                  <div class="text-center mt-1">
                    <v-btn width="150" depressed dense> Browse File </v-btn>
                  </div>
                </div>
              </div>
              <div v-else class="dropZone-info">
                <span class="fa fa-cloud-upload dropZone-title"></span>
                <v-img
                  v-if="!process.upload"
                  aspect-ratio="4"
                  contain
                  class="mx-auto"
                  :src="filepreview"
                >
                </v-img>
                <div v-if="process.upload">
                  <v-progress-linear
                    indeterminate
                    color="primary">
                  </v-progress-linear>
                  <span>
                    Uploading Image . . .
                  </span>
                </div>
              </div>
              <form ref="fileUploaded">
                <input
                  accept="image/x-png,image/gif,image/jpeg"
                  ref="inputFile"
                  type="file"
                  @change="onChange"
                />
              </form>
            </div>

            <v-scroll-y-transition>
              <v-alert
                dense
                outlined
                text
                icon="ri-alert-line"
                color="error"
                class="mb-1 mt-5"
                v-show="error.image_url.length > 0"
              >
                <div class="subtitle-2">{{ error.image_url }}</div>
              </v-alert>
            </v-scroll-y-transition>
            <!-- IMAGE UPLOAD -->

            <v-row no-gutters class="mt-3">
              <v-col cols="6" sm="6">
                <div class="d-flex align-center">
                  <div 
                    style="
                      margin-right: 10px;
                      font-weight: 500;
                      font-size: 16px;">
                    Service Name
                  </div>
                  <div
                    v-if="is_active"
                    style="
                      color: #652065;
                      font-size: 12px;">
                    Maintenance Aktif
                  </div>
                  <div
                    v-else
                    style="
                      color: #652065;
                      font-size: 12px;">
                    Announcement aktif
                  </div>
                </div>
                <div>
                  Pengaturan sistem maintenance
                </div>
              </v-col>
              <v-col cols="1" sm="1">
                <v-switch
                  v-model="is_active"
                  inset>
                </v-switch>
              </v-col>
            </v-row>

            <!-- <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="user.company.name"
                  label="Nama"
                  filled
                  readonly
                  outlined
                  dense
                  class="mt-3">
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="user.company.description"
                  label="Description"
                  filled
                  readonly
                  outlined
                  dense
                  class="mt-3">
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="user.company.email"
                  label="Email"
                  filled
                  readonly
                  outlined
                  dense
                  class="mt-3">
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="user.company.phone_number"
                  label="Nomor Telephone"
                  filled
                  readonly
                  outlined
                  dense
                  class="mt-3">
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-row no-gutters class="mt-5">
                  <v-col cols="10" sm="10">
                    <div class="d-flex align-center">
                      <div 
                        style="
                          margin-right: 10px;
                          font-weight: 500;
                          font-size: 16px;">
                        Mode Penggunaan Biteship
                      </div>
                      <div
                        v-if="user.company.settings.is_use_biteship"
                        style="
                          color: #652065;
                          font-size: 12px;">
                        Mode Aktif
                      </div>
                      <div
                        v-else
                        style="
                          color: #8492A6;
                          font-size: 12px;">
                        Non aktif
                      </div>
                    </div>
                    <div>
                      Pengaturan sistem maintenance
                    </div>
                  </v-col>
                  <v-col cols="1" sm="1">
                    <v-switch
                      v-model="user.company.settings.is_use_biteship"
                      disabled
                      inset>
                    </v-switch>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row no-gutters class="mt-5">
                  <v-col cols="10" sm="10">
                    <div class="d-flex align-center">
                      <div 
                        style="
                          margin-right: 10px;
                          font-weight: 500;
                          font-size: 16px;">
                        Mode Penggunaan Diskualifikasi Test
                      </div>
                      <div
                        v-if="user.company.settings.is_use_disqualified_test"
                        style="
                          color: #652065;
                          font-size: 12px;">
                        Mode Aktif
                      </div>
                      <div
                        v-else
                        style="
                          color: #8492A6;
                          font-size: 12px;">
                        Non aktif
                      </div>
                    </div>
                    <div>
                      Pengaturan sistem maintenance
                    </div>
                  </v-col>
                  <v-col cols="1" sm="1">
                    <v-switch
                      v-model="user.company.settings.is_use_disqualified_test"
                      disabled
                      inset>
                    </v-switch>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row no-gutters class="mt-5">
                  <v-col cols="10" sm="10">
                    <div class="d-flex align-center">
                      <div 
                        style="
                          margin-right: 10px;
                          font-weight: 500;
                          font-size: 16px;">
                        Mode Penggunaan Institution
                      </div>
                      <div
                        v-if="user.company.settings.is_use_institution"
                        style="
                          color: #652065;
                          font-size: 12px;">
                        Mode Aktif
                      </div>
                      <div
                        v-else
                        style="
                          color: #8492A6;
                          font-size: 12px;">
                        Non aktif
                      </div>
                    </div>
                    <div>
                      Pengaturan sistem maintenance
                    </div>
                  </v-col>
                  <v-col cols="1" sm="1">
                    <v-switch
                      v-model="user.company.settings.is_use_institution"
                      disabled
                      inset>
                    </v-switch>
                  </v-col>
                </v-row>
              </v-col>
            </v-row> -->
          </v-form>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions class="pb-2 px-7">
        <v-spacer></v-spacer>
        <v-btn 
          width="100"
          elevation="0"
          color="#652065"
          class="text-capitalize white--text"
          :loading="process.run"
          :disabled="process.run"
          @click="save()">
          Simpan
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      file: [],
      errors: {},
      draging: false,
      /**
       * Attachment
       */
      dragging: false,
      fileUpload: [],
      filepreview: '',
      error: {
        image_url: "",
      },
      process: {
        run: false,
        upload: true,
      },
      images: [],
      is_active: false,
      notification: {
        state: false,
        text: "",
        color: ""
      },
      detailCompany: {},
      form: {
        name: "",
        description: "",
        phone_number: "",
        email: "",
        image: "",
        setting: {
          use_biteship: false,
          use_disqualified: false,
          use_institution: false,
          announcement: {
            images: [],
            is_active: false
          }
        },
        certificate: {
          image: "",
          signature: "",
          subtitle: "" 
        }
      },
    };
  },
  computed: {
    FormartedDate() {
      // format date with moment format
      return moment(this.form.maintenance_date).format("DD MMMM YYYY");
    },
    user() {
      return JSON.parse(localStorage.getItem("ytc_auth"));
    }
  },
  mounted() {
    this.DetailCompany()
  },
  methods: {
    removeFile() {
      this.$refs.fileUploaded.reset();
      this.fileUpload = [];
      this.filepreview = "";
      this.images = [];
      this.$refs.inputFile.value = null;
    },
    onChange(e) {
      let src = {
        url: null,
        title: null,
        alt: null,
        description: null,
        caption: null,
      }
      var files = e.target.files || e.dataTransfer.files;
      let selectedFiles = e.target.files[0] || e.dataTransfer.files[0];

      if (this.draging) {
        this.draging = false;
        this.filepreview = URL.createObjectURL(selectedFiles);
        src.url = URL.createObjectURL(selectedFiles);
        src.title = selectedFiles.name;
        src.alt = selectedFiles.name;
        src.file = selectedFiles;
      } else {
        this.filepreview = URL.createObjectURL(e.target.files[0]);
        src.url = URL.createObjectURL(e.target.files[0]);
        src.title = e.target.files[0].name;
        src.alt = e.target.files[0].name;
        src.file = e.target.files[0];
      }

      src.source = "upload";

      if (!files.length) {
        this.dragging = false;
        return;
      }

      this.fileUpload = files;
      this.setAttachment(src)
    },
    async setAttachment(file){
      this.process.upload = true;
      await this.$store
        .dispatch("media/create", file)
        .then((res) => {
          console.log(res);
          // this.images.push(res.data);
          if (this.images.length == 0) {
            this.images.push(res.data.id);
          }else {
            this.images[0] = res.data.id
          }
          this.process.upload = false
        })
        .catch((error) => {
          this.errors.message = error.message;
          this.saving = false;
        });
    },
    validation() {
      var error = this.error,
        valid = true;

      if (this.is_active) {
        if (this.images.length == 0) {
          error.image_url = "Gambar Tidak Boleh Kosong";
          valid = false;
        } else {
          error.image_url = "";
        }
      }
      return valid;
    },
    async DetailCompany() {
      await this.axios.get(`/company/v1/get/company/${this.user.company.id}`)
        .then((response) => {
          let res = response.data
          if (res.status == 200) {
            this.detailCompany = res.data
            this.process.upload = false;
            this.filepreview = res.data.settings.announcement.images[0].url;
            this.images.push(res.data.settings.announcement.images[0].id)
            this.is_active = res.data.settings.announcement.is_active;
          }else{
            console.log(res.message);
          }
        })
        .catch((error) => {
          this.errors.message = error.message;
          this.saving = false;
        });
    },
    async save() {
      this.process.run = true;
      const isValid = await this.$refs.observer.validate()
      const Valids = await this.validation()
      if (isValid && Valids) {
        let data =  {
          name: this.detailCompany.name,
          description: this.detailCompany.description,
          phone_number: this.detailCompany.phone_number,
          email: this.detailCompany.email,
          image: this.detailCompany.image.id,
          setting: {
            use_biteship: this.detailCompany.settings.is_use_biteship,
            use_disqualified: this.detailCompany.settings.is_use_disqualified_test,
            use_institution: this.detailCompany.settings.is_use_institution,
            announcement: {
              images: this.images,
              is_active: this.is_active
            },
            email: {
              after_exam: {
                enable: this.detailCompany.settings.email.after_exam.enable,
                template: this.detailCompany.settings.email.after_exam.template
              },
              import_buyer: {
                enable: this.detailCompany.settings.email.import_buyer.enable,
                template: this.detailCompany.settings.email.import_buyer.template
              }
            }
          },
          certificate: {
            image: this.detailCompany.certificate.image.id,
            signature: this.detailCompany.certificate.signature.id,
            subtitle: this.detailCompany.certificate.subtitle
          },
          additional_fields: this.detailCompany.additional_fields
        }

        await this.axios.put(`/company/v1/update/company/${this.user.company.id}`, data)
        .then(response => {
          let res = response.data
          if (res.status == 200) {
            this.process.run = false
            this.notification.state = true;
            this.notification.color = "#4CAF50";
            this.notification.text = "Pengaturan Announcement berhasil di ubah";
            this.DetailCompany()
          }else {
            this.process.run = false
          }
        }).catch(error => {
          console.log(error);
          this.notification.state = true;
          this.notification.color = "error";
          this.notification.text = "Pengaturan Announcement gagal di ubah";
        })
      }else {
        this.process.run = false;
      }
    },
  },
};
</script>

<style>
.dropZone {
  width: 100%;
  min-height: 400px;
  height: auto;
  position: relative;
  border: 3px dashed #ddd;
}

.dropZone:hover {
  border: 2px solid #652065;
}

.dropZone:hover .dropZone-title {
  color: #652065;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #5c5c5c;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 100%;
  height: 200px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}
</style>