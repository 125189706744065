var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"shadow-0",staticStyle:{"background":"transparent"},attrs:{"elevation":"0"}},[_c('v-snackbar',{attrs:{"color":_vm.color,"top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"small":"","icon":"","color":"white"},on:{"click":function($event){_vm.notification.state = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("$close")])],1)]}}]),model:{value:(_vm.notification.state),callback:function ($$v) {_vm.$set(_vm.notification, "state", $$v)},expression:"notification.state"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.notification.text)}})]),_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"600px"},model:{value:(_vm.dialog.filtered),callback:function ($$v) {_vm.$set(_vm.dialog, "filtered", $$v)},expression:"dialog.filtered"}},[_c('v-card',{staticClass:"border-radius box-shadow"},[_c('v-card-title',{staticClass:"body-1 text-first d-flex mb-4",staticStyle:{"background-color":"#652065","height":"50px"}},[_c('span',{staticClass:"white--text"},[_vm._v(" Dialog Filter ")]),_c('v-spacer'),_c('v-icon',{attrs:{"size":"30","color":"white"},on:{"click":function($event){_vm.dialog.filtered = false}}},[_vm._v("ri-close-line")])],1),_c('v-card-text',{staticClass:"py-5",staticStyle:{"height":"350px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"mt-3"},[_vm._v(" Tanggal Mulai Tes ")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatStartDate,"placeholder":"Pilih Tanggal Mulai Tes","outlined":"","dense":"","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu.start_date),callback:function ($$v) {_vm.$set(_vm.menu, "start_date", $$v)},expression:"menu.start_date"}},[_c('v-date-picker',{on:{"change":function($event){_vm.menu.start_date = false}},model:{value:(_vm.form.filter_start_date),callback:function ($$v) {_vm.$set(_vm.form, "filter_start_date", $$v)},expression:"form.filter_start_date"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"mt-3"},[_vm._v(" Tanggal Selesai Tes ")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatEndDate,"placeholder":"Pilih Tanggal Selesai Tes","outlined":"","dense":"","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu.end_date),callback:function ($$v) {_vm.$set(_vm.menu, "end_date", $$v)},expression:"menu.end_date"}},[_c('v-date-picker',{on:{"change":function($event){_vm.menu.end_date = false}},model:{value:(_vm.form.filter_end_date),callback:function ($$v) {_vm.$set(_vm.form, "filter_end_date", $$v)},expression:"form.filter_end_date"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"white--text text-capitalize",attrs:{"width":"100","color":"#652065","elevation":"0","disabled":_vm.process.run,"loading":_vm.process.run},on:{"click":function($event){return _vm.initialize()}}},[_vm._v(" Filter ")]),_c('v-btn',{staticClass:"text-capitalize white--text",attrs:{"width":"100","elevation":"0","color":"yellow darken-2","disabled":_vm.process.run},on:{"click":function($event){return _vm.resetFilter()}}},[_vm._v(" Reset ")])],1)],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"d-flex text-right"},[_c('v-text-field',{staticClass:"mr-3",attrs:{"outlined":"","autocomplete":"off","dense":"","prepend-inner-icon":"ri-search-line","placeholder":"Cari Nama / Email . . .","hide-details":"auto"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.initialize(_vm.searching, '')}},scopedSlots:_vm._u([(_vm.searching)?{key:"append",fn:function(){return [_c('v-icon',{on:{"click":function($event){_vm.searching = '';
                _vm.initialize();}}},[_vm._v("ri-close-line")])]},proxy:true}:null],null,true),model:{value:(_vm.searching),callback:function ($$v) {_vm.searching=$$v},expression:"searching"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dense":"","small":"","elevation":"0"},on:{"click":function($event){_vm.dialog.filtered = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#652065"}},[_vm._v("ri-filter-line")])],1)]}}])},[_c('span',[_vm._v("Filter")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dense":"","small":"","elevation":"0"},on:{"click":function($event){return _vm.actionExport()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#4CAF50"}},[_vm._v("ri-file-excel-2-line")])],1)]}}])},[_c('span',[_vm._v("Donwload Excel")])])],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mb-10"},[_c('v-data-table',{attrs:{"loading":_vm.process.run,"disable-sort":"","headers":_vm.headers,"items":_vm.process.run ? [] : _vm.listData.list,"items-per-page":_vm.listData.limit,"page":_vm.listData.page,"item-key":"id","mobile-breakpoint":0,"server-items-length":_vm.listData.total,"loading-text":"Loading... Please wait","hide-default-footer":""},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.listData, "limit", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.listData, "limit", $event)},"update:page":function($event){return _vm.$set(_vm.listData, "page", $event)}},scopedSlots:_vm._u([{key:"footer",fn:function(ref){
                var props = ref.props;
return [_c('v-row',{staticClass:"px-4"},[_c('div',{staticClass:"pa-3"},[_c('v-row',{attrs:{"align":"center"}},[_c('div',[_c('v-subheader',{domProps:{"textContent":_vm._s('Baris Per Halaman: ')}})],1),_c('div',[_c('v-select',{staticClass:"mt-0 pt-0",staticStyle:{"width":"4rem"},attrs:{"dense":"","items":[10, 20, 50, 100],"hide-details":"auto"},model:{value:(_vm.listData.limit),callback:function ($$v) {_vm.$set(_vm.listData, "limit", $$v)},expression:"listData.limit"}})],1)])],1),_c('v-col',[_c('v-row',{staticClass:"mx-3",attrs:{"justify":"end","align":"center"}},[_c('div',{staticClass:"d-flex flex-nowrap align-center"},[_c('v-subheader',{staticClass:"mr-2"},[_vm._v("Halaman:")]),_c('v-select',{staticClass:"pa-0 ma-0 mr-2",staticStyle:{"width":"5rem","text-align":"center !important"},attrs:{"dense":"","hide-details":"","items":Array.from(
                          {
                            length: Math.ceil(
                              _vm.listData.total / _vm.listData.limit
                            ),
                          },
                          function (_, i) { return i + 1; }
                        )},model:{value:(_vm.listData.page),callback:function ($$v) {_vm.$set(_vm.listData, "page", $$v)},expression:"listData.page"}}),_c('v-subheader',[_vm._v(" Menampilkan data ke "+_vm._s(props.pagination.pageStart + 1)+" sampai "+_vm._s(props.pagination.pageStop)+" dari "+_vm._s(props.pagination.itemsLength)+" ")])],1),_c('v-btn',{attrs:{"icon":"","small":"","disabled":_vm.listData.page == 1},on:{"click":function($event){_vm.listData.page--}}},[_c('v-icon',[_vm._v("$prev")])],1),_c('v-btn',{attrs:{"icon":"","small":"","disabled":_vm.listData.page ==
                        Math.ceil(_vm.listData.total / _vm.listData.limit)},on:{"click":function($event){_vm.listData.page++}}},[_c('v-icon',[_vm._v("$next")])],1)],1)],1)],1)]}},{key:"item.member.fullname",fn:function(ref){
                        var item = ref.item;
return [_c('span',{staticClass:"text-left"},[_vm._v(_vm._s(item.member.fullname))])]}},{key:"item.member.place_of_birth",fn:function(ref){
                        var item = ref.item;
return [_c('span',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.member.place_of_birth)+" ")])]}},{key:"item.start_test",fn:function(ref){
                        var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.convertStartTest(item.start_test))+" ")])]}},{key:"item.cheating",fn:function(ref){
                        var item = ref.item;
return [_c('span',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.isCheating(item))+" ")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }